<template>
  <div id="Manage">
    <!-- 组织架构 管理人员-->

    <!-- Dialog 对话框 -->
    <el-dialog :title="dialogFonfig.openType === 'add' ? dialogFonfig.addName : dialogFonfig.editName"
               :visible.sync="dialogFonfig.isShowDialogVisible"
               @close="clickCancelData">
      <CommonForm :form="openForm"
                  :formLabel="openFormLabel"></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="primary"
                   @click="clickSubmitData(dialogFonfig.openType, openForm)">确 定</el-button>
      </div>
    </el-dialog>
    <!-- Dialog 对话框 状态表单-->
    <el-dialog :title="dialogFonfigStatus.statusName"
               style="width:1000px;margin-left:25%;margin-top:5%"
               :close-on-click-modal="false"
               :visible.sync="dialogFonfigStatus.isShowDialogVisible">
      <CommonForm :form="statusForm"
                  :formLabel="statusFormLabel"
                  inline></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="primary"
                   @click="clickSubmitStatusData(statusForm)">保 存</el-button>
      </div>
    </el-dialog>

    <!-- 查看管理人员表单 -->
    <el-dialog title="查看管理人员角色"
               style="width:1500px;margin-left:10%"
               :close-on-click-modal="false"
               :visible.sync="dialogFonfigcheck.isShowDialogVisible"
               @close="clickCancelData">
      <CommonTabForm :form="openForm"
                     :tabLabel.sync="tabFormLabel"
                     :tableData="powerDate"
                     :activeTabName.sync="activeTabName"
                     ref="elTable"></CommonTabForm>
    </el-dialog>
    <!-- Dialog 对话框 编辑角色表单-->
    <el-dialog title="编辑管理人员角色"
               style="width:1000px;margin-left:25%;margin-top:5%"
               :close-on-click-modal="false"
               :visible.sync="dialogFonfigedit.isShowDialogVisible"
               @close="clickCancelData">
      <CommonForm :form="editForm"
                  :formLabel="editFormLabel"
                  inline></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="primary"
                   @click="clickSubmiteditData(editForm)">保 存</el-button>
      </div>
    </el-dialog>
    <CommonManageMain>
      <template v-slot:header>
        <div>
          <el-button type="primary"
                     size="small"
                     @click="clickAddData"
                     icon="el-icon-circle-plus-outline">增加</el-button>
          <CommonForm :form="searchForm"
                      :formLabel="searchformLabel"
                      class="filtrateInput">
            <el-button type="primary"
                       @click="searchKeyword(searchForm)">搜索</el-button>
            <el-button>导出</el-button>
            <el-button type="text">查看已导出列表</el-button>
            <el-button type="text"
                       @click="resetFilter(searchForm)">重置筛选条件</el-button>
          </CommonForm>
        </div>
      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTable :tableData="tableData"
                     :tableLabel="tableLabel"
                     :tableConfig="tableConfig"
                     @getPage="getPage"
                     style="height:510px">
          <el-table-column label="操作"
                           width="200">
            <template slot-scope="scope">
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="编辑"
                          placement="top">
                <span @click="clickEditData(scope.row)">编辑</span>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="状态"
                          placement="top">
                <span @click="changeStatus(scope.row)">状态</span>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="查看"
                          placement="top">
                <span @click="checkPower(scope.row)">查看可赋角色</span>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="编辑角色"
                          placement="top">
                <span @click="editRole(scope.row)">编辑角色</span>
              </el-tooltip>
            </template>
          </el-table-column>
        </CommonTable>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import {
  getUserList, methUserData, postUserStateData,
  getUserOfRoleData, getUserRole, postUserAuthorizeRoleData
} from 'api/organize.js'
import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonTabForm from 'components/CommonTab_Form.vue'
import CommonManageMain from 'components/CommonManageMain.vue'

export default {
  components: {
    CommonForm,
    CommonTable,
    CommonManageMain,
    CommonTabForm
  },
  data () {
    return {
      dialogFonfigcheck: {
        isShowDialogVisible: false
      },
      activeTabName: 'first',
      tabFormLabel: [
        {
          name: 'first',
          label: "角色列表",
          type: 'table',
          //tab-table表单数据
          formTable: {
            id: '',
            name: '',
            description: '',
            hold: '',
          },
          tableLabel: [
            {
              prop: 'id',
              label: '角色ID',
              width: '180px'
            },
            {
              prop: 'name',
              label: '名称',
              width: '180px'
            },
            {
              prop: 'description',
              label: '角色描述',
              width: '180px'
            },
            {
              prop: 'hold',
              label: '是否拥有',
              width: '150px',
              type: 'hold'
            },
          ],
          tableConfig: {
            isShowLoading: false,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            // 加载状态
            loading: false,
            // 显示 选择多行数据
            selection: false,
            //row key
            rowKey: 'date',
            //显示表格top按钮
            isShowTopBtn: false,
          },
        },
      ],
      powerDate: [],


      searchData: '',//存放搜索的数据，分页时使用
      // 对话框 Dialog 基础数据
      dialogFonfig: {
        openType: 'add',
        addName: '新增管理人员',
        editName: '编辑管理人员',
        isShowDialogVisible: false
      },

      // form表单 基础数据
      openForm: {
        name: '',
        phone: '',
        email: '',
        password: '',
        avatar: '',
        sex: ''
      },
      openFormLabel: [
        {
          model: 'name',
          label: '姓名',
        },
        {
          model: 'password',
          label: '密码'
        },
        {
          model: 'phone',
          label: '手机号',
        },
        {
          model: 'email',
          label: '邮箱'
        },
        {
          model: 'avatar',
          label: '头像',
          type: 'upload',
          imageUrl: '',
          maxSize: 2048,
          imgHeight: '1280',
          imgWidth: '808',
          clues: '只能上传jpg/png文件，且格式为80x80'
        },
        {
          model: 'sex',
          label: '性别',
          type: 'select',
          options: [
            { value: 0, label: '女' },
            { value: 1, label: '男' },
          ]
        },
      ],
      // 对话框 Dialog 状态
      dialogFonfigStatus: {
        statusName: '编辑管理人员状态',
        isShowDialogVisible: false
      },

      //form表单 
      statusForm: {
        id: '',
        remarks: '',
        status: ''
      },
      statusFormLabel: [
        {
          model: 'id',
          label: '管理人员ID',
          width: '400px',
          type: 'disabled'
        },
        {
          model: 'status',
          label: '状态',
          type: 'select',
          options: [
            { value: 'close', label: '禁用' },
            { value: 'open', label: '激活' },
            { value: 'del', label: '删除' },
          ],
        },

      ],
      dialogFonfigedit: {
        isShowDialogVisible: false
      },

      //form表单 审核
      editForm: {
        id: '',
        role_id: []
      },
      editFormLabel: [
        {
          model: 'id',
          label: '管理人员ID',
          width: '400px',
          type: 'disabled'
        },
        {
          model: 'role_id',
          label: '角色',
          type: 'treeSelect',
          options: [],
          defaultProps: {
            label: "label",
            children: "children"
          },
          collapseTags: false,
          nodeKey: 'value',
        },

      ],
      optionsPower: [],
      // form表单 搜索数据
      searchForm: {
        name: '',
        start_time: '',
        end_time: '',
        status: '',
      },
      searchformLabel: [
        {
          model: 'name',
          label: '姓名',
          width: '300px'
        },
        {
          model: 'start_time',
          label: '创建时间 开始',
          type: 'datetime',
          width: '300px'
        },
        {
          model: 'end_time',
          label: '创建时间 结束',
          type: 'datetime',
          width: '300px'
        },
        {
          model: 'status',
          label: '状态',
          type: 'select',
          width: '300px',
          options: [
            // value:'值',label:'标签'
            { value: 0, label: '禁用' },
            { value: 1, label: '启用' },
          ],
        },
      ],
      // table 表格 基础数据
      tableData: [],
      tableLabel: [
        {
          prop: 'id',
          label: '管路员ID',
        },
        {
          prop: 'username',
          label: '用户名称'
        },
        {
          prop: 'name',
          label: 'name'
        },
        {
          prop: 'avatar',
          label: '头像',
          type: 'image'
        },
        {
          prop: 'phone',
          label: '电话'
        },
        {
          prop: 'email',
          label: '电子邮件'
        },
        {
          prop: 'sex',
          label: '性别',
          type: 'sex'
        },
        {
          prop: 'status',
          label: '状态',
          type: 'stateSW',
        },
        {
          prop: 'last_login_time',
          label: '最新登录时间'
        }
      ],
      tableConfig: {
        isShowLoading: true,
        // 改造状态列显示效果 el-switch

        page: 1,
        total: 30,
        // 加载状态
        loading: false,
        // 显示 选择多行数据
        selection: false,
      },

      brandAllDataObj: {},
      barndFormatDealListData: [],
      // category_id最大值
      MaxId: ''
    }
  },
  mounted () {
    this.getUserList()
  },
  watch: {
    brandAllDataObj: function () {
      this.tableData = this.dataFormatDeal(
        this.brandAllDataObj.list,
        {
        },
        0
      )
    },
    //角色选择
    optionsPower: function () {
      this.editFormLabel[1].options = this.dataFormatDeal(this.optionsPower, {
        id: 'value',
        name: 'label',
        child: 'children',
      }, 1)
    },
  },

  methods: {
    //获得下列分类页的页数
    getPage () {
      if (this.searchData) {
        this.searchData.page = this.tableConfig.page
        this.getUserList(this.searchData)
      } else {
        this.getUserList()
      }
    },
    //   获取--管理人员列表数据
    getUserList (data) {
      if (data) {
        getUserList(data).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.brandAllDataObj = DATA.data
            this.tableConfig.total = DATA.data.total
          }
        })
      } else {
        getUserList({
          page: this.tableConfig.page,
          page_rows: 10
        }).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.brandAllDataObj = DATA.data

            this.tableConfig.total = DATA.data.total
            this.tableConfig.loading = false
          }

        })
      }
    },
    // 获取角色
    getUserRole (data) {
      console.log(data)
      getUserRole(data).then(res => {
        const Data = res.data
        console.log(Data)
        if (Data.code == 200) {
          this.optionsPower = Data.data
        } else {
          this.$message({
            message: Data.message,
            type: 'error'
          })
        }
      })
    },
    // 更新数据  -- 添加-编辑 管理人员
    methUserData (met, data) {
      methUserData(met, data).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          this.getUserList()
        } else {
          this.$message({
            message: res.data.message,
            type: 'error'
          })
        }
      })
    },
    //改变管理人员状态  接口
    postUserStateData (data) {
      return new Promise(resolve => {
        postUserStateData(data).then((res) => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            resolve(res)
          } else {
            this.$message({
              message: DATA.message,
              type: 'warning'
            })
          }
        })
      })
    },

    //查看管理人员角色
    getUserOfRoleData (data) {
      return new Promise(resolve => {
        getUserOfRoleData(data).then((res) => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            resolve(res)
          } else {
            this.$message({
              message: DATA.message,
              type: 'warning'
            })
          }
        })
      })
    },

    //编辑管理人员权限  接口
    postUserAuthorizeRoleData (data) {
      return new Promise(resolve => {
        postUserAuthorizeRoleData(data).then((res) => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            resolve(res)
          } else {
            this.$message({
              message: DATA.message,
              type: 'warning'
            })
          }
        })
      })
    },

    // 数据格式处理
    dataFormatDeal (arr, keyMap, copyInKeyMap) {
      // global 全局方法处理

      return this.globalDeepCloneData(arr, keyMap, copyInKeyMap)
    },

    // 获取最大的 category_id值
    MaxCatgroyId (arr, keyMap) {
      let maxid = 0
      for (const val of arr) {
        maxid = maxid > val[keyMap] ? maxid : val[keyMap]
      }
      return maxid
    },

    // 添加数据
    clickAddData () {
      this.MaxId = this.MaxCatgroyId(this.tableData, 'id')
      this.openForm.id = this.MaxId + 1
      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'add'
    },

    // 确定提交数据操作
    clickSubmitData (type, data) {
      if (type == 'add') {
        console.log(DATA)
        const DATA = {
          name: data.name,
          phone: data.phone,
          email: data.email,
          password: data.password,
          avatar: data.avatar,
          sex: data.sex
        }
        console.log(DATA)
        this.methUserData('POST', DATA)
      }
      else if (type == 'edit') {
        const DATA = {
          id: data.id,
          name: data.name,
          phone: data.phone,
          email: data.email,
          password: data.password,
          avatar: data.avatar,
          sex: data.sex
        }
        this.methUserData('PUT', DATA)
      }
      this.dialogFonfig.isShowDialogVisible = false
      this.getUserList()
    },
    // 取消提交数据操作
    clickCancelData () {
      this.dialogFonfig.isShowDialogVisible = false
      this.dialogFonfigStatus.isShowDialogVisible = false
      this.dialogFonfigedit.isShowDialogVisible = false
      // 用于当前 Vue 实例的初始化选项
      this.openForm = this.$options.data().openForm
      this.statusForm = this.$options.data().statusForm
      this.editForm = this.$options.data().editForm
      this.powerDate = []
    },

    // 编辑操作
    clickEditData (row) {
      console.log(row)
      this.openForm = {

        id: row.id,
        name: row.name,
        phone: row.phone,
        email: row.email,
        password: row.password,
        avatar: row.avatar,
        sex: row.sex,
      }
      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'edit'
    },
    //编辑管理人员状态操作
    changeStatus (row) {
      if (row.status == 0) {
        this.statusForm = {
          id: row.id,
          status: 'close'
        }
      } else if (row.status == 1) {
        this.statusForm = {
          id: row.id,
          status: 'open'
        }
      } else {
        this.statusForm = {
          id: row.id,
          status: 'del'
        }
      }
      this.dialogFonfigStatus.isShowDialogVisible = true
    },
    //提交状态表单
    async clickSubmitStatusData (data) {
      console.log(data)
      await this.postUserStateData(data).then(async (res) => {
        if (res.data.code == 200) {
          await this.getUserList()
        }
      })
      this.dialogFonfigStatus.isShowDialogVisible = false
    },
    //查看管理人员权限
    async checkPower (row) {
      console.log(row)
      const DATA = {
        admin_user_id: row.id
      }
      await this.getUserOfRoleData(DATA).then(async (res) => {
        console.log(res.data.data)
        const powerArray = res.data.data.map(item => {
          return {
            id: item.id,
            name: item.name,
            description: item.description,
            hold: item.hold
          }
        })
        this.powerDate = this.powerDate.concat(powerArray)
      })
      this.dialogFonfigcheck.isShowDialogVisible = true
    },
    //编辑角色操作
    editRole (row) {
      console.log(row)
      const DATA = {
        admin_user_id: row.id
      }
      let powerList = this.getUserRole(DATA)
      Promise.all([powerList]).then(res => {
        this.dialogFonfigedit.isShowDialogVisible = true
      })
      this.editForm = {
        id: row.id,
        role_id: row.role_id
      }
    },
    //提交编辑角色
    async clickSubmiteditData (data) {
      const DATA = {
        admin_user_id: data.id,
        role_id: data.role_id
      }
      await this.postUserAuthorizeRoleData(DATA).then(async (res) => {
        if (res.data.code == 200) {
          await this.getUserList()
        }
      })
      this.dialogFonfigedit.isShowDialogVisible = false
    },
    //处理标准时间
    dateToMs (date) {
      let result = new Date(date).getTime();
      return result;
    },
    //搜索
    searchKeyword (searchForm) {
      console.log(searchForm.start_time)
      console.log(searchForm.end_time)
      if (searchForm.start_time == '') {
        searchForm.start_time = 0
      }
      if (searchForm.end_time == '') {
        searchForm.end_time = 0
      }
      searchForm.start_time = this.dateToMs(searchForm.start_time)
      searchForm.end_time = this.dateToMs(searchForm.end_time)
      const data = {
        name: searchForm.name,
        start_time: searchForm.start_time / 1000,
        end_time: searchForm.end_time / 1000,
        status: searchForm.status,
      }
      this.searchData = data
      this.getUserList(data)
    },
    //重置筛选条件
    resetFilter (searchForm) {
      searchForm.name = '',
        searchForm.start_time = '',
        searchForm.end_time = '',
        searchForm.status = '',
        this.getUserList()
    }
  }
}
</script>

<style lang="scss" scoped>
#Manage {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
  .el-transfer {
    text-align: center;
    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }
  /deep/ .el-dialog {
    width: 50%;
  }
  .filtrateInput {
    background: #f8f7f7;
    padding: 12px 8px;
    padding-right: 45%;
    margin: 8px 0px;
  }
  span {
    font-size: 14 px;
    margin-left: 8px;
    color: #0486fe;
    cursor: pointer;
  }
}
</style>
